<template>
    <div class="h-full flex flex-col max-h-full">
        <div class="flex max-h-full" :class="!package_id ? 'h-full flex-shrink-0' : ' '">
            <div class="w-full px-4 py-4 min-h-full">
                <div class="items-center space-x-4 mb-6" v-if="!package_id">
                    <div class="text-md font-semibold text-gray-600">Packages</div>
                </div>

                <div class="grid gap-4 overflow-y-auto scroll-bar" :class="!show_case_form ? 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' : 'grid-cols-1 h-full w-full'">
                    <!-- <template v-if="package_id">
                        <div class="card bg-white bordered shadow w-full max-w-6xl">
                            <div class="card-header bg-gray-100 border-b border-solid bordered px-6 pt-3">
                                <h4 class="card-title flex gap-4 wide:gap-6 text-gray-700 text-sm font-bold">
                                    Create Case

                                    <template v-if="selectedPackage && selectedPackage.package_name">
                                        <div class="flex items-center text-sm gap-2">
                                            <div class="font-semibold text-gray-600 mb-0">Package Name:</div>
                                            <span class="text-gray-500 text-sm"> ({{ selectedPackage.package_name }}) </span>
                                        </div>
                                    </template>
                                </h4>
                            </div>
                            <FormulateForm name="create_case" #default="{ hasErrors }" class="flex-grow flex overflow-hidden">
                                <div class="card-body min-full flex flex-col justify-start gap-4 scroll-bar overflow-y-auto flex-grow">
                                    <div class="flex justify-center gap-x-4 items-start mb-4">
                                        <div for="#" class="label justify-end text-right w-1/3">
                                            Title
                                            <span class="text-red-500 text-lg"> *</span>
                                        </div>
                                        <FormulateInput class="w-2/3" placeholder="Title" type="text" validation="required" v-model="caseForm.title" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>

                                    <div class="flex justify-center gap-x-4 items-start mb-4">
                                        <div for="#" class="label justify-end text-right w-1/3">
                                            First Name
                                            <span class="text-red-500 text-lg"> *</span>
                                        </div>
                                        <FormulateInput class="w-2/3" placeholder="First Name" type="text" validation="required" v-model="caseForm.first_name" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>

                                    <div class="flex justify-center gap-x-4 items-start mb-4">
                                        <div for="#" class="label justify-end text-right w-1/3">Middle Name</div>
                                        <FormulateInput class="w-2/3" placeholder="Middle Name" type="text" v-model="caseForm.middle_name" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>

                                    <div class="flex justify-center gap-x-4 items-start mb-4">
                                        <div for="#" class="label justify-end text-right w-1/3">
                                            Last Name
                                            <span class="text-red-500 text-lg"> *</span>
                                        </div>
                                        <FormulateInput class="w-2/3" placeholder="Last Name" validation="required" type="text" v-model="caseForm.last_name" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>

                                    <div class="flex justify-center gap-x-4 items-start mb-4">
                                        <div for="#" class="label justify-end text-right w-1/3">
                                            Phone Number
                                            <span class="text-red-500 text-lg"> *</span>
                                        </div>
                                        <FormulateInput class="w-2/3" placeholder="Phone Number" type="text" v-model="caseForm.phone" validation="required" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>
                                    <div class="flex justify-center items-start mb-4 gap-x-4">
                                        <div for="#" class="label justify-end text-right w-1/3">
                                            Email Address
                                            <span class="text-red-500 text-lg"> *</span>
                                        </div>
                                        <FormulateInput class="w-full" placeholder="Email Address" type="text" validation="required|email" v-model="caseForm.email" outer-class="mb-4 w-full" input-class="border input-bordered rounded px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" help-class="text-xs mb-1 text-gray-600" error-class="text-red-700 text-xs mb-1" />
                                    </div>

                                    <div class="flex justify-center items-start mb-4 gap-x-4">
                                        <div for="#" class="label justify-end text-right w-1/3">Checks included in the Packages</div>
                                        <div class="w-full border-gray-200 scroll-bar rounded-md max-h-40 overflow-y-auto flex flex-col gap-2 p-3" :class="packageLoading || !(packageChecks && packageChecks.length) ? 'border items-center justify-center border-solid border-gray-200 text-sm text-gray-500' : ''">
                                            <Loader v-if="packageLoading" :size="8" class="mx-auto my-5" />
                                            <ul class="flex flex-wrap gap-2" v-if="!packageLoading && packageChecks && packageChecks.length">
                                                <li v-for="pack in packageChecks" :key="pack.id" class="bg-gray-100 text-sm text-gray-600 shadow-sm rounded-lg px-3 py-1.5">
                                                    <font-awesome-icon icon="check" />
                                                    {{ pack.name }}
                                                </li>
                                            </ul>
                                            <span v-if="!packageLoading && packageChecks && !packageChecks.length">No Checks included in the Packages </span>
                                        </div>
                                    </div>

                                    <div class="flex justify-center items-start mb-4 gap-x-4">
                                        <div class="text-right w-1/3">Addon checks</div>
                                        <div class="w-full">
                                            <vue-multiselect :multiple="true" :limit="1" :checkboxes="true" :taggable="false" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="Addon checks" :show-labels="false" :maxHeight="200" :options="allChecks" :disabled="allChecksFetching && !allChecks.length" :loading="allChecksFetching && !allChecks.length" selectLabel="" track-by="id" label="name" v-model="caseForm.addon_checks"> </vue-multiselect>
                                            <div class="scroll-bar rounded-md max-h-40 mt-4 overflow-y-auto flex flex-col gap-2" :class="!caseForm.addon_checks.length ? 'border items-center justify-center border-solid border-gray-200 text-sm text-gray-500' : ''">
                                                <ul class="flex flex-wrap py-4 gap-2" v-if="caseForm.addon_checks && caseForm.addon_checks.length">
                                                    <li v-for="addOncheck in caseForm.addon_checks" :key="addOncheck.id" class="border border-gray-200 text-xs text-black shadow-sm rounded-full px-2 py-1 flex font-normal justify-between items-center gap-4">
                                                        {{ addOncheck.name }}
                                                        <button class="bg-white p-1 rounded-half w-4 flex items-center justify-center h-4 text-xs" @click="handleRemoveCheck(addOncheck)">
                                                            <font-awesome-icon class="text-gray-600 text-xs" icon="times" />
                                                        </button>
                                                    </li>
                                                </ul>
                                                <span v-else>No Addon checks added</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="flex justify-end gap-x-x">
                                        <Button text="Submit Case" type="primary" :loader="isCaseCreatingInProgress" :disabled="isCaseCreatingInProgress" @click="submitCase(hasErrors)" />
                                    </div>
                                </div>
                            </FormulateForm>
                        </div>
                    </template> -->
                    <template v-if="show_case_form">
                        <div class="flex flex-col">
                            <div class="mb-4 flex items-center justify-between">
                                <Button text="Back" outline type="primary" @click="cancelCaseForm" />
                                <button @click="deleteCase" type="button" class="text-red-500 hover:text-white-text border-2 border-red-500 hover:bg-red-600 rounded-xl font-bold leading-tight text-sm px-4 py-2 text-center mr-2">Cancel Case</button>
                            </div>

                            <FormBuilder :data="case_form_data" :saveSectionMethod="saveSection" @submit="submitForm" :gapErrors="{}" :printableSections="false" :userInfo="{}" :case_id="temp_case_id" />
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="loaders.packages">
                            <div class="flex-grow w-full col-span-4 flex py-12 shadow">
                                <Loader :size="8" class="mx-auto my-5" />
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="packages && packages.length > 0">
                                <div v-for="tenant_package in packages" :key="tenant_package.id" class="items-stretch">
                                    <packageCard class="h-full" :tenant_package="tenant_package" :progress="true" @create="caseCreate" />
                                </div>
                            </template>
                            <template v-else>
                                <AlertInfo type="info" message="No packages found!" />
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <modal-content ref="create-case-modal" name="create-case-modal" title="Create Case" @submit="createTempCase" submitButtonText="Next" :disabled="loaders.modal">
            <div class="p-4 flex flex-col max-h-full overflow-y-auto">
                <div class="mb-2">
                    <div class="label mainLabel font-medium">Checks included in the Packages</div>
                    <div class="w-full border-gray-200 scroll-bar rounded-md max-h-40 overflow-y-auto flex flex-col gap-2 py-1" :class="packageLoading || !(packageChecks && packageChecks.length) ? 'border items-center justify-center border-solid border-gray-200 text-sm text-gray-500' : ''">
                        <Loader v-if="packageLoading" :size="8" class="mx-auto my-5" />
                        <ul class="flex flex-wrap gap-2" v-if="!packageLoading && packageChecks && packageChecks.length">
                            <li v-for="pack in packageChecks" :key="pack.id" class="bg-gray-100 text-sm text-gray-600 shadow-sm rounded-lg px-3 py-1.5">
                                <font-awesome-icon icon="check" />
                                {{ pack.name }}
                            </li>
                        </ul>
                        <span v-if="!packageLoading && packageChecks && !packageChecks.length">No Checks included in the Packages </span>
                    </div>
                </div>

                <div class="mb-2">
                    <div class="mb-2 mainLabel font-medium">Addon checks</div>
                    <div class="w-full">
                        <vue-multiselect :multiple="true" :limit="1" :checkboxes="true" :taggable="false" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="Addon checks" :show-labels="false" :maxHeight="200" :options="allChecks" :disabled="allChecksFetching && !allChecks.length" :loading="allChecksFetching && !allChecks.length" selectLabel="" track-by="id" label="name" v-model="caseForm.addon_checks"> </vue-multiselect>
                        <div class="scroll-bar rounded-md max-h-40 overflow-y-auto flex flex-col gap-2" :class="!caseForm.addon_checks.length ? 'border items-center justify-center border-solid border-gray-200 text-sm text-gray-500' : ''">
                            <ul class="flex flex-wrap py-2 gap-2" v-if="caseForm.addon_checks && caseForm.addon_checks.length">
                                <li v-for="addOncheck in caseForm.addon_checks" :key="addOncheck.id" class="border border-gray-200 text-xs text-black shadow-sm rounded-full px-2 py-1 flex font-normal justify-between items-center gap-4">
                                    {{ addOncheck.name }}
                                    <button class="bg-white p-1 rounded-half w-4 flex items-center justify-center h-4 text-xs" @click="handleRemoveCheck(addOncheck)">
                                        <font-awesome-icon class="text-gray-600 text-xs" icon="times" />
                                    </button>
                                </li>
                            </ul>
                            <span v-else>No Addon checks added</span>
                        </div>
                    </div>
                </div>

                <!-- Collect subject info check -->
                <div class="form-control mb-3">
                    <label class="label cursor-pointer justify-start space-x-3">
                        <input ref="collectdat" type="checkbox" @click="handleCheckInfo" class="checkbox  checkbox-primary" checked/>
                        <span class="label-text">Collect Information from Subject</span> 
                    </label>
                </div>

                <div class="flex flex-col" v-if="selectedPackage" v-show="false">
                    <FormulateInput class="w-full" placeholder="Subject link valid duration (Days)" label="Subject link valid duration (Days)" type="number" validation="number|min:0,number"  :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="selectedPackage.candidate_form_link_valid_duration" />
                    <FormulateInput v-if="ifCheckisReference > 0" class="w-full" label="Referee link valid duration (Days)" placeholder="Referee link valid duration (Days)" type="number" validation="number|min:0,number"  :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="selectedPackage.reference_form_link_valid_duration" />
                    <FormulateInput class="w-full" label="Additional form link valid duration (Days)" placeholder="Additional form link valid duration (Days)" type="number" validation="number|min:0,number"  :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="selectedPackage.additional_form_link_valid_duration" />
                </div>

                <div class="flex flex-col flex-1 overflow-y-auto max-h-full">
                    <div class="mb-2 mainLabel font-medium">Case Allocation</div>
                    <div class="max-h-full border-solid py-2 px-4 border border-gray-300 scroll-bar flex-grow flex flex-1 gap-4 rounded overflow-y-auto">
                        <div class="relative border border-solid border-gray-100 rounded-lg shadow-lg flex-1 flex flex-col max-h-full" v-for="caseAll in filteredCaseAllocation" :key="caseAll.name">
                            <div class="text-base tracking-wide bg-blue-100 py-1 px-2 text-blue-600 capitalize rounded-t">{{ caseAll.name }}</div>
                            <div class="flex items-center p-2">
                                <div class="flex items-center">
                                    <input :id="`checkbox-${caseAll.name}`" type="checkbox" v-model="caseAll.selectAll" @change="onSelectAllocation($event, caseAll.name)" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 flex-shrink-0" />
                                    <label :for="`checkbox-${caseAll.name}`" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 selectCheck">
                                        <template v-if="caseAll.selectAll"> Deselect all </template>
                                        <template v-else> Select all </template>
                                    </label>
                                </div>
                                <div class="relative w-full p-2">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                    </div>
                                    <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5" placeholder="Search" v-model="search[caseAll.name]" />
                                </div>
                            </div>
                            <div class="flex p-2 overflow-y-auto max-h-full flex-1">
                                <div class="bg-white rounded-lg overflow-y-auto shadow w-full border border-gray-200 flex flex-col scroll-bar">
                                    <ul class="divide-y divide-gray-100 flex flex-col">
                                        <template v-if="loaders.modal">
                                            <li class="flex-grow w-full col-span-4 flex py-12 shadow">
                                                <Loader :size="8" class="mx-auto my-5" />
                                            </li>
                                        </template>

                                        <template v-else-if="caseAll.items.length > 0">
                                            <li class="p-2 flex items-center truncate" :class="{ 'bg-gray-200': index % 2 == 0 }" v-for="(item, index) in caseAll.items" :key="item.id">
                                                <input :id="`checkbox-${item.id}`" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-2" :checked="item.selected" @change="onChangeResources($event, caseAll.name, item.id)" />
                                                <label :for="`checkbox-${item.id}`">
                                                    <template v-if="caseAll.name === 'users'">
                                                        {{ item.user_name }}
                                                    </template>
                                                    <template v-else>
                                                        {{ item.title }}
                                                    </template>
                                                </label>
                                            </li>
                                        </template>
                                        <template v-else>
                                            <li class="p-2 flex items-center">No Results found!</li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="px-4 text-primary font-bold">User(s) Selected: {{ countUsersSelected }}</div>
                        <div class="px-4 text-primary font-bold">Group(s) Selected: {{ countGroupsSelected }}</div>
                    </div>
                    <Button  class="btn bottom-8 right-8 w-auto" @click="createTempCase" type="primary" @keyup.enter="submitModal" text="Next" :loader="loaders.createCase" :disabled="loaders.createCase" />
                </div>
            </template>
        </modal-content>
    </div>
</template>

<script>
// import clientCard from "@/components/client-card";
// import Input from "@/components/input";
import packageCard from "@/components/package-card";
import axios from "@/axios";
import AlertInfo from "@/components/alert-box";
import Loader from "@/components/loader";
import Button from "@/components/button";
// import { countries } from "@/plugins/constants";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import { getChecksList,getPackagesCheck } from "@/modules/checks/services.js";
import modalContent from "@shared/modal-content";
import FormBuilder from "@shared/components/form-builder";
import { cloneDeep } from "lodash";

export default {
    name: "create-case",
    components: {
        // clientCard,
        // Input,
        modalContent,
        FormBuilder,
        Button,
        packageCard,
        AlertInfo,
        Loader,
        VueMultiselect,
    },
    data() {
        return {
            clients: [],
            selectedClient: null,
            packages: [],
            loaders: {
                clients: false,
                packages: false,
                case: false,
                modal: false,
                createCase: false,
            },
            searchKeywordClients: "",
            package_id: null,
            caseForm: {
                email: "",
                title: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                phone: "",
                country: "",
                addon_checks: [],
            },
            client_package_id: null,
            packageChecks: [],
            allChecks: [],
            allChecksFetching: false,
            packageLoading: false,
            isCaseCreatingInProgress: false,
            temp_case_id: "",
            case_form_data: [],
            show_case_form: false,
            search: {
                users: "",
                groups: "",
            },
            caseAllocation: [
                { name: "users", selectAll: false, items: [] },
                { name: "groups", selectAll: false, items: [] },
            ],
            collect_personal_data: true,
            isCollectInfoChecked: true,
        };
    },

    computed: {
        filteredClients() {
            return this.clients.filter((cli) => cli.name.toLowerCase().includes(this.searchKeywordClients.toLowerCase()));
        },

        selectedPackage() {
            return this.package_id ? this.packages.find((pkg) => pkg.package_id === this.package_id) : null;
        },
        filteredChecks() {
            let newChecksList = this.allChecks.filter((e) => {
                for (let i of this.packageChecks) {
                    if (i.id === e.id) {
                        return false;
                    }
                }
                return true;
            });
            return newChecksList;
        },
        filteredCaseAllocation() {
            let caseAllocation = cloneDeep(this.caseAllocation);
            console.log("caseAllocation :>> ", caseAllocation);
            return caseAllocation.map((allocation) => {
                let obj = allocation;
                const itemKey = obj.name === "users" ? "user_name" : "title";
                obj.items = obj.items.filter((item) => item[itemKey].toLowerCase().includes(this.search[obj.name].toLowerCase()));
                obj.selectAll = obj.items.every((item) => item.selected);
                obj.count = obj.items?.filter((item) => item.selected)?.length ?? 0;
                return obj;
            });
        },
        countUsersSelected() {
            return this.filteredCaseAllocation.find((allocation) => allocation.name === "users").count ?? 0;
        },
        countGroupsSelected() {
            return this.filteredCaseAllocation.find((allocation) => allocation.name === "groups").count ?? 0;
        },
        ifCheckisReference(){
            return this.packageChecks.filter(el => el.category.toLowerCase() === 'reference').length
        }
    },
    async mounted() {
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        // await this.fetchAllClients();
        const clientid = this.$store.getters.getClientId;

        if (clientid) await this.fetchPackages(clientid);
    },
    created() {
        // if (this.package_id) this.fetchAllPackageChecks();
    },
    methods: {
        async fetchAllPackageChecks() {
            this.packageLoading = true;
            this.packageChecks = [];
            const package_id = this.package_id;
            try {
                const { data } = await axios.get(`screening-package/${package_id}/client-checks`);
                this.packageChecks = data.checks;
            } catch (error) {
                console.log("error :>> fetchAllPackageChecks", error);
            }

            this.packageLoading = false;
        },
        async fetchChecksList() {
            this.allChecks = [];
            this.caseForm.addon_checks = [];
            this.allChecksFetching = true;
            try {
                const { data } = await axios.get(`tenant-check/client-checks`);
                this.allChecks = data.data;
            } catch (error) {
                console.log("error :>> fetchChecksList", error);
            }
            this.allChecksFetching = false;
        },

        handleRemoveCheck(check) {
            this.caseForm.addon_checks = this.caseForm.addon_checks?.filter((el) => el.id !== check.id);
        },

        async fetchPackages() {
            try {
                this.packages = [];
                this.loaders.packages = true;
                const { data } = await axios(`screening-package/client-packages`);
                this.packages = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.packages = false;
        },
        cancelCase() {
            this.package_id = null;
            this.caseForm = {};
        },
        async isCaseAlreadyExists() {
            let packages = cloneDeep(this.packages);
            let index = packages.findIndex((pack) => pack.package_id === this.package_id);
            packages[index].isLoading = true;
            this.packages = packages;
            try {
                const { data } = await axios.post("/case/incomplete-created-case", {
                    package_id: this.package_id,
                    client_id: this.$store.getters.getClientId,
                });
                packages[index].isLoading = false;
                this.packages = packages;
                return data;
            } catch (error) {
                packages[index].isLoading = false;
                this.packages = packages;
                console.log(error, "[+] error while fetching case state");
            }
        },
        async caseCreate(tenant_package) {
            console.log("tenant_package :>> ", tenant_package);
            this.package_id = tenant_package.package_id;
            this.tenant_package_id = tenant_package.package_id;
            this.client_package_id = tenant_package.id;
            const isExists = await this.isCaseAlreadyExists();
            if (isExists.case_id) {
                this.temp_case_id = isExists.case_id;
                await this.getCaseFormData(this.temp_case_id);
            } else {
                this.$refs["create-case-modal"].showModal();
                this.loaders.modal = true;
                if (this.package_id) {
                    await this.fetchAllPackageChecks();
                    await this.fetchChecksList();
                    await this.fetchResources("users");
                    await this.fetchResources("groups");
                }
                this.loaders.modal = false;
            }
        },

        // todo remove this
        // async submitCase(hasErrors) {
        //     if (hasErrors) {
        //         this.$toast.error(`Please fill all the required fields before submitting`);
        //         return;
        //     }
        //     // let addons = []
        //     let addon_checks = this.caseForm.addon_checks.map((el) => {
        //         return el.id;
        //     });
        //     const client_id = this.$store.getters.getClientId;
        //     const params = {
        //         package_id: this.client_package_id,
        //         email: this.caseForm.email,
        //         title: this.caseForm.title,
        //         first_name: this.caseForm.first_name,
        //         last_name: this.caseForm.last_name,
        //         phone: this.caseForm.phone,
        //         country: this.caseForm.country,
        //         middle_name: this.caseForm.middle_name,
        //         client_id,
        //         addon_checks,
        //     };
        //     this.isCaseCreatingInProgress = true;
        //     try {
        //         const { data } = await axios.post(`/case/case-creation-form/${this.temp_case_id}/submit`, params);
        //         if (data && data.success) {
        //             this.$toast.success(`${data.message} - ${data.data.case_id}`);
        //             this.handleRestCase();
        //         }
        //     } catch (error) {
        //         this.$toast.error(error.response.data.detail || error.message);
        //         console.log("error creating case :>> ", error);
        //     }
        //     this.isCaseCreatingInProgress = false;
        // },

        // todo remove this

        handleRestCase() {
            this.$formulate.reset("create_case");
            this.caseForm.addon_checks = [];
        },

        handleCheckInfo(e) {
            this.collect_personal_data = e.target.checked;
            if(this.collect_personal_data){
                this.isCollectInfoChecked = true;
            }
            else{
                this.isCollectInfoChecked = false;
            }
        },

        async createTempCase() {
            // create temp case
            try {
                const client_id = this.$store.getters.getClientId;
                const allocated_client_users = this.caseAllocation
                    .find((allocation) => allocation.name == "users")
                    .items.filter((elem) => elem.selected)
                    .map((elem) => elem.id);
                const allocated_client_groups = this.caseAllocation
                    .find((allocation) => allocation.name == "groups")
                    .items.filter((elem) => elem.selected)
                    .map((elem) => elem.id);
                let body = {
                    package_id: this.client_package_id,
                    client_id,
                    addon_checks: this.caseForm.addon_checks.map((el) => el.id) || [],
                    allocated_client_users,
                    allocated_client_groups,
                    candidate_form_link_expiry: parseInt(this.selectedPackage.candidate_form_link_valid_duration),
                    reference_form_link_expiry: parseInt(this.selectedPackage.reference_form_link_valid_duration),
                    additional_form_link_expiry: parseInt(this.selectedPackage.additional_form_link_valid_duration),
                    collect_personal_data: this.collect_personal_data,
                };
                // email_sequence: this.caseForm?.email_sequence?.id || "",
                if (this.caseForm && this.caseForm.email_sequence && this.caseForm.email_sequence.id) {
                    body.email_sequence = this.caseForm.email_sequence.id;
                }
                this.loaders.createCase = true;
                const { data } = await axios.post(`case/create-temp-case`, body);
                this.loaders.createCase = false;
                // console.log('{ data } :>> ', { data });
                this.temp_case_id = data.case_id;
                // get form data
                await this.getCaseFormData(this.temp_case_id);
               
            } catch (error) {
                console.log("error :>> createTempCase ", error);
                this.$toast.warning(error.response.data.detail || `Something went wrong!`);
                this.loaders.createCase = false;
            } finally {
                this.loaders.createCase = false;
            }
            this.collect_personal_data = true;
        },

        async getCaseFormData(case_id) {
            try {
                this.loaders.modal = true;
                this.loaders.createCase = true;
                const { data } = await axios(`case/case/${case_id}/case-creation-form-v2`);
                this.case_form_data = data || [];
            } catch (error) {
                console.log("error :>> ", error);
                this.loaders.createCase = false;
            } finally {
                this.loaders.createCase = false;
                this.loaders.modal = false;
                this.$refs["create-case-modal"].hideModal();
                this.show_case_form = true;
            }
        },

        async saveSection(payload) {
            console.log("payload :>> ", payload);
            let sectionSaved = false;
            try {
                await axios.post(`/case/case-creation-form/${this.temp_case_id}`, this.formatSections(payload));
                sectionSaved = true;
            } catch (error) {
                console.log("error :>> ", error);
                sectionSaved = false;
            }
            return sectionSaved;
        },
        /* eslint-disable no-mixed-spaces-and-tabs */
        formatSections(data) {
            return { sections: [data.data] };
        },
        async submitForm(payload) {
            try {
                const sectionSaved = await this.saveSection(payload);
                if (sectionSaved) {
                    await this.submitCaseForm(this.temp_case_id);
                }
            } catch (error) {
                console.log("error :>> submitForm ", error);
            }
        },
        async submitCaseForm(caseId) {
            const url = `/case/case-creation-form/${caseId}/submit`;
            try {
                const response = await axios.post(url);
                this.$toast.success(response?.data?.message || "Case created!");
                this.show_case_form = false;
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to create case");
                return false;
            }
        },
        cancelCaseForm() {
            this.show_case_form = false;
            // this.selectedPackage = null;
            this.package_id = null;
            // this.$router.push({ query: { client: this.selectedClient } });
        },
        async deleteCase() {
            try {
                await axios.delete(`/case/${this.temp_case_id}`);
                this.cancelCaseForm();
            } catch (error) {
                console.log(error, "[+] Error while deleting case");
            }
        },
        async fetchResources(type) {
            let url = "";
            if (type === "users") {
                url = `/user/client/${this.$store.getters.getClientId}`;
            } else {
                url = `/group/client/${this.$store.getters.getClientId}`;
            }
            try {
                const { data } = await axios.get(url);
                const index = this.caseAllocation.findIndex((allocation) => allocation.name === type);
                if (index != -1) {
                    this.caseAllocation[index].items = data?.map((elem) => ({ ...elem, selected: false }));
                }
            } catch (error) {
                console.log(error, "[+] error while fetching users");
            }
        },
        onSelectAllocation({ target }, type) {
            let caseAllocation = cloneDeep(this.caseAllocation);
            const index = caseAllocation.findIndex((allocation) => allocation.name === type);
            // console.log('event :>> ', event);
            caseAllocation[index].items = caseAllocation[index].items.map((elem) => ({ ...elem, selected: target.checked }));
            this.caseAllocation = caseAllocation;
        },
        onChangeResources({ target }, type, id) {
            let caseAllocation = cloneDeep(this.caseAllocation);
            const index = caseAllocation.findIndex((allocation) => allocation.name === type);
            const itemIndex = caseAllocation[index].items.findIndex((item) => item.id === id);
            caseAllocation[index].items[itemIndex].selected = target.checked;
            this.caseAllocation = caseAllocation;
        },
    },
};
</script>
<style lang="scss" scoped>
.mainLabel {
    font-size: 14px;
}
.selectCheck {
    font-size: 12px;
}
::v-deep {
    .formulate-input .formulate-input-element {
        max-width: 40em !important;
    }
    .multiselect__tag {
        background: #f2f2f2;
        color: rgb(49, 49, 49);
    }
    .multiselect__tag-icon:hover {
        background: rgb(255, 212, 212);
    }
}
</style>
